import React from "react";
import ReactDOM from "react-dom";
// 
import "antd/dist/antd.css";
import "./m.css";
// 
import Router from "./routes/index";
// import "./index.css";
import "./main.css";
import "./index.css";
// 

ReactDOM.render(<Router />, document.getElementById("root"));
