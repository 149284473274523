export default function RobotOnStraightPath({ pathHeight, pathEnd, ...props }) {

    return (
        <svg {...props} id="svg-robot-on-straight-path" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 880">
            <path strokeDasharray="5,5" stroke="#AFF1FF" d="M60,30 L60,700" />
            <path d="M85.9029 92.4435C93.5183 87.7336 99.4257 80.7093 102.76 72.3991C106.095 64.089 106.681 54.9295 104.433 46.2621C102.186 37.5946 97.2219 29.8744 90.2692 24.232C83.3164 18.5896 74.7399 15.3214 65.7953 14.9059C56.8508 14.4904 48.0082 16.9495 40.5624 21.9231C33.1165 26.8967 27.4586 34.1235 24.4171 42.5453C21.3756 50.9671 21.1103 60.1415 23.6601 68.7249C26.2098 77.3084 31.4406 84.8501 38.5866 90.2456L63.85 56.7864L85.9029 92.4435Z" fill="url(#svg-robot-on-straight-path-0_radial)" />
            <g clipPath="url(#svg-robot-on-straight-path-clip-0)">
                <path opacity="0.12" d="M62.937 87.1878C67.424 87.1451 71.0532 86.247 71.0431 85.182C71.0329 84.117 67.3873 83.2883 62.9003 83.331C58.4133 83.3738 54.7841 84.2718 54.7943 85.3368C54.8044 86.4019 58.4501 87.2306 62.937 87.1878Z" fill="black" />
                <path d="M62.4916 84.9671C62.4916 84.9671 76.5943 85.8529 79.379 67.2419C79.4532 66.7505 79.4935 66.2546 79.5017 65.7574C79.5605 61.4674 78.8108 40.1891 62.018 38.984L61.9762 38.9844C44.7511 40.3565 44.8653 61.798 45.0059 66.0861C45.0219 66.5831 45.0733 67.0781 45.1568 67.5679C48.2956 86.1225 62.3982 84.9664 62.3982 84.9664L62.4916 84.9671Z" fill="url(#svg-robot-on-straight-path-1_linear)" />
                <path d="M82.6905 88.0263L79.7207 88.0546C79.7207 88.0546 73.7117 75.4362 62.3463 75.9638C50.973 75.6512 45.2056 88.3835 45.2056 88.3835L42.2373 88.4118C39.3252 79.1335 44.4646 68.8782 45.1553 67.5681C45.3081 68.4087 44.5313 71.4879 62.3021 71.3185C80.0728 71.1492 79.185 68.1458 79.361 67.3604C80.0764 68.6571 85.4252 78.6927 82.6905 88.0263Z" fill="#1B134E" />
                <path d="M62.3015 67.4129C62.2921 67.4307 62.2684 67.4762 62.2322 67.5443C62.2604 67.4762 62.2888 67.4308 62.3015 67.4129Z" stroke="#270D0D" strokeMiterlimit="10" />
                <path d="M62.3028 67.5436C62.2652 67.4762 62.2407 67.4312 62.231 67.4136C62.244 67.4312 62.2732 67.4761 62.3028 67.5436Z" stroke="#270D0D" strokeMiterlimit="10" />
                <path d="M71.7642 59.5097C71.8098 64.2878 68.37 68.1891 62.2727 68.2472C56.1754 68.3053 52.6651 64.4702 52.6196 59.6922C52.5741 54.9141 56.0105 51.0032 62.1078 50.9451C68.2052 50.887 71.7187 54.7317 71.7642 59.5097Z" fill="#262635" />
                <path d="M62.0586 58.7795C58.1541 58.8167 54.8388 60.7558 53.5292 63.4579C54.8901 66.1346 58.4511 68.379 62.3557 68.3418C66.2602 68.3046 69.6857 65.9418 70.9953 63.2397C69.636 60.5646 65.9616 58.7423 62.0586 58.7795Z" fill="#363649" />
                <path d="M59.4016 60.2183C60.0351 60.2123 60.5387 59.1613 60.5264 57.871C60.5141 56.5806 59.9905 55.5394 59.357 55.5455C58.7235 55.5515 58.2199 56.6024 58.2322 57.8928C58.2445 59.1832 58.768 60.2243 59.4016 60.2183Z" fill="#42E8E0" />
                <path d="M65.7468 60.1566C66.3803 60.1506 66.8839 59.0997 66.8716 57.8093C66.8593 56.5189 66.3358 55.4778 65.7022 55.4838C65.0687 55.4899 64.5651 56.5408 64.5774 57.8312C64.5897 59.1215 65.1133 60.1627 65.7468 60.1566Z" fill="#42E8E0" />
                <path opacity="0.4" d="M67.3617 53.5699C67.6318 53.5673 67.8487 53.3448 67.8461 53.073C67.8435 52.8011 67.6225 52.5829 67.3524 52.5854C67.0822 52.588 66.8654 52.8105 66.868 53.0823C66.8706 53.3541 67.0916 53.5724 67.3617 53.5699Z" fill="white" />
                <path opacity="0.4" d="M69.4757 55.9124C69.3291 55.8944 69.1994 55.7969 69.1433 55.6484C68.8742 54.9256 68.0155 54.3038 68.0074 54.2974C67.8243 54.1664 67.7801 53.9094 67.9102 53.7251C68.0404 53.5393 68.2958 53.4964 68.4789 53.6274C68.5226 53.6578 69.5497 54.3993 69.908 55.361C69.9873 55.574 69.8801 55.8098 69.6685 55.8896C69.6044 55.9128 69.5385 55.9199 69.4757 55.9124Z" fill="#FCFDFF" />
                <path d="M45.205 88.3833L46.3964 87.9639C46.3964 87.9639 51.7278 77.0866 62.3473 75.9621C62.3473 75.9621 51.9056 74.8082 45.205 88.3833Z" fill="#110B35" />
                <path d="M79.7576 88.0543L78.5583 87.6577C78.5583 87.6577 73.0207 76.884 62.3816 75.962C62.3832 75.9619 72.8011 74.6094 79.7576 88.0543Z" fill="#110B35" />
                <path opacity="0.2" d="M54.2126 81.5162L58.1272 81.3591C58.1272 81.3591 61.9554 83.9409 70.5196 81.8952C70.5196 81.8968 61.982 86.7323 54.2126 81.5162Z" fill="#F2F2F2" />
                <path d="M42.0446 72.956L43.4784 71.119L44.4734 73.5611L41.4213 80.1515L42.0446 72.956Z" fill="url(#svg-robot-on-straight-path-2_linear)" />
                <path d="M43.4784 71.119L43.84 73.4441L41.4213 80.1515L44.4734 73.5611L43.4784 71.119Z" fill="#C1C1C1" />
                <path d="M82.5781 72.5696L80.978 70.7923L80.1612 73.2209L83.3384 79.752L82.5781 72.5696Z" fill="url(#svg-robot-on-straight-path-3_linear)" />
                <path d="M80.978 70.7923L80.7907 73.0918L83.3384 79.752L80.1612 73.2209L80.978 70.7923Z" fill="#C1C1C1" />
                <g opacity="0.39">
                    <path opacity="0.39" d="M67.9385 45.5959C68.2435 46.3719 68.4 47.9314 68.4415 48.4071C68.4488 48.4896 68.5153 48.5537 68.5974 48.5578C71.0203 48.7031 71.5678 47.5693 71.6801 47.2055C71.7005 47.1421 71.6805 47.0727 71.6286 47.0295C70.6587 46.2161 69.4262 45.5332 69.0251 45.32C68.9604 45.285 68.8801 45.2971 68.8307 45.351C68.7384 45.4507 68.5192 45.5872 68.0139 45.5774L67.9303 45.5782L67.9385 45.5959Z" fill="white" />
                    <path opacity="0.39" d="M68.5573 44.1035C68.4678 43.991 68.1509 43.6621 67.3539 43.4284C67.3329 43.4221 67.315 43.4061 67.3068 43.3851L66.6403 41.6556C66.6189 41.6024 66.665 41.5485 66.7214 41.5593C67.197 41.6552 68.9211 42.0938 70.4269 43.5934C70.4675 43.6335 70.4457 43.705 70.3895 43.7152C70.0763 43.7733 69.2733 43.9299 68.6348 44.1271C68.6059 44.1371 68.5769 44.1276 68.5573 44.1035Z" fill="white" />
                    <path opacity="0.39" d="M66.1059 43.795C66.0825 44.2098 66.2159 44.5226 66.314 44.6901C66.3581 44.7658 66.3221 44.8617 66.2403 44.8916C65.7448 45.0696 64.2873 45.6 63.6871 45.9247C63.6246 45.9577 63.5489 45.9406 63.5081 45.8827C63.195 45.4436 62.0551 43.6798 62.9576 42.4826C62.9942 42.4337 63.0567 42.4153 63.1132 42.4342C63.499 42.5665 65.0069 43.1059 66.0295 43.7083C66.0392 43.7147 66.0489 43.721 66.0571 43.7291L66.0928 43.7644L66.1059 43.795Z" fill="white" />
                    <path opacity="0.39" d="M68.3359 44.9622C68.5025 44.7161 68.2859 44.2778 67.8551 43.9839C67.4227 43.6901 66.9381 43.6526 66.7715 43.8987C66.6049 44.1448 66.8215 44.5832 67.2522 44.877C67.6846 45.1725 68.1693 45.21 68.3359 44.9622Z" fill="white" />
                </g>
            </g>
            <path d="M79.4232 111.443C76.6209 113.62 73.4174 115.223 69.9956 116.161C66.5738 117.099 63.0006 117.355 59.4802 116.912C55.9597 116.469 52.5609 115.338 49.4778 113.582C46.3947 111.826 43.6877 109.48 41.5113 106.677L62.8502 90.1043L79.4232 111.443Z" fill="url(#svg-robot-on-straight-path-4_radial)" />
            <defs>
                <radialGradient id="svg-robot-on-straight-path-0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(63.85 56.7864) rotate(118.284) scale(30.7455)">
                    <stop stopColor="#AFF1FF" />
                    <stop offset="1" stopColor="#AFF1FF" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="svg-robot-on-straight-path-1_linear" x1="47.7936" y1="77.1393" x2="76.7056" y2="47.8593" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBDBF" />
                    <stop offset="0.5832" stopColor="#DDDEDF" />
                    <stop offset="1" stopColor="#F1F1F2" />
                </linearGradient>
                <linearGradient id="svg-robot-on-straight-path-2_linear" x1="43.8434" y1="76.5977" x2="40.9204" y2="74.6051" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBDBF" />
                    <stop offset="0.5832" stopColor="#DDDEDF" />
                    <stop offset="1" stopColor="#F1F1F2" />
                </linearGradient>
                <linearGradient id="svg-robot-on-straight-path-3_linear" x1="80.8343" y1="76.2248" x2="83.7189" y2="74.1768" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBDBF" />
                    <stop offset="0.5832" stopColor="#DDDEDF" />
                    <stop offset="1" stopColor="#F1F1F2" />
                </linearGradient>
                <radialGradient id="svg-robot-on-straight-path-4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(62.8502 90.1043) rotate(-61.7156) scale(27.0187)">
                    <stop stopColor="#C4C4C4" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                </radialGradient>
                <clipPath id="svg-robot-on-straight-path-clip-0">
                    <rect width="42.1105" height="49.2369" fill="white" transform="translate(40.939 39.1848) rotate(-0.545915)" />
                </clipPath>
            </defs>
        </svg>)
}