import React from 'react'
import robotFooter  from '../../svg/footer.svg'

const LiveAuctionFooter = ()=> {
    return (
        <div className="live-auction-footer">
        <img src={robotFooter} alt="icont" />
        </div>
    )
}
export default LiveAuctionFooter