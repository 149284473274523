import React from 'react'

export default function robotFlag(props) {
    return (
        <svg {...props} id="svg-robot-flag" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1177 606" fill="none">
            <g clipPath="url(#svg-robot-flag-clip-0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M363.885 326C363.885 326 370.919 341.653 376.57 350.681C382.221 359.709 387.271 365.424 387.271 365.424L381.92 374.127L186.542 383.74L0 401.341C0 401.341 10.821 418.033 15.3297 422.84C19.7783 427.646 25.9102 431.867 25.9102 431.867C25.9102 431.867 29.8178 452.327 31.2005 460.77C32.5832 469.213 29.0363 477.007 29.0363 477.007L38 498L302.627 566.053H472.516L817.104 601.515L1176 621V419.462L850.529 368.737L363.885 326Z" fill="#3285D0" />
                <path fillRule="evenodd" clipRule="evenodd" d="M437.759 372.283C437.759 372.283 421.521 364.526 411.057 363.264C400.593 362.001 134.473 371.682 118.596 372.283L83.1135 369.517C83.1135 369.517 75.9568 361.761 68.8603 363.144C61.7638 364.526 55.8701 367.653 55.8701 367.653C55.8701 367.653 53.284 375.831 51.0589 377.875C48.8337 379.979 39.2113 381.603 39.2113 381.603L31.8742 387.435L20.4476 379.919L7.93848 387.435L0 396.274C0 396.274 19.2448 398.799 25.0784 400.423C30.912 402.106 121.603 396.695 148.305 400.423C175.068 404.211 205.138 404.211 207.182 406.255C209.287 408.36 236.952 414.012 247.115 412.809C257.279 411.667 360.178 396.214 360.178 396.214L419.416 383.948L437.759 372.283Z" fill="url(#svg-robot-flag-0_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M1177 305H706.706C706.706 305 694.858 310.111 686.499 310.772C678.14 311.494 579.931 312.696 566.099 312.937C552.266 313.177 514.859 311.073 491.224 312.937C467.589 314.801 437.459 315.943 428.017 317.627C418.575 319.31 391.753 322.076 382.07 323.76C372.388 325.443 362.775 338.234 365 341C367.225 343.766 382.242 341.652 390 345.5C397.818 349.408 441.141 346.775 449.5 349C457.799 351.225 501.087 350.998 511.371 354.365C521.655 357.732 530.015 360.498 530.015 360.498C530.015 360.498 441.248 370.539 430.182 373.846C419.177 377.214 409.133 379.979 409.133 379.979C409.133 379.979 275.502 395.553 262.392 400.603C249.281 405.594 241.042 407.819 242.606 411.186C244.229 414.553 292.221 417.86 303.347 419.002C314.413 420.145 363.427 407.338 371.786 411.787C380.146 416.237 389.167 419.002 393.858 422.911C398.549 426.819 413.103 423.452 425.852 426.819C438.602 430.186 644.16 432.952 655.286 435.177C666.412 437.402 676.456 447.984 688.724 450.75C700.993 453.516 1176.4 454.117 1176.4 454.117L1177 305Z" fill="url(#svg-robot-flag-1_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M1114.45 454.718L1124.86 592.171L1003.74 595.057L842.802 581.889L823.136 515.387L807.861 454.718L806.056 520.318L807.861 563.85L780.617 592.171L713.381 577.86L688.723 450.75L655.285 435.177L643.197 505.887L629.305 512.02L634.297 547.375L556.295 549.54L442.149 553.328L425.911 426.759L415.928 425.256L427.656 496.026L398.608 424.534L396.683 520.679L385.197 469.089L361.321 468.548L363.306 410.825L303.406 418.942L307.616 460.01L262.15 466.263L236.531 460.01L204.115 405.353L150.47 402.708L166.347 435.598L170.196 453.997L179.879 500.776L135.856 491.396L107.771 425.917L105.365 410.945L96.645 399.22L25.0784 400.423L0 396.274C0 396.274 11.9077 413.11 15.3357 416.176C18.7035 419.243 25.8602 424.534 25.8602 424.534L49.3749 426.278L65.5526 436.62L77.6407 454.057L52.8029 460.07L43.3008 466.864L29.9497 468.548L36.2043 487.608L76.2575 505.947L379.724 572.749L738.279 603.054L1124.8 605.94L1176.4 599.566V454.057L1114.45 454.718Z" fill="url(#svg-robot-flag-2_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M214.76 466.323L228.231 544.489C228.231 544.489 270.51 560.603 287.289 563.85C304.068 567.097 323.433 556.094 323.433 556.094L354.405 553.328L375.694 538.897L385.197 469.089L332.333 468.548L214.76 466.323Z" fill="url(#svg-robot-flag-3_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M214.76 466.323C214.76 466.323 265.999 446.842 272.975 447.383C279.952 447.924 303.346 447.383 303.346 447.383C303.346 447.383 322.29 455.741 333.957 456.883C345.624 458.025 385.197 469.149 385.197 469.149C385.197 469.149 358.013 473.599 349.293 473.599L352.841 479.19C352.841 479.19 333.897 482.558 304.97 479.19C304.97 479.13 281.034 474.08 214.76 466.323Z" fill="url(#svg-robot-flag-4_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M31.2106 458.867L51.1169 456.462L62.6638 457.665L52.861 460.01L43.3589 466.804L29.9476 468.548C29.9476 468.548 27.3015 463.677 31.2106 458.867Z" fill="url(#svg-robot-flag-5_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M521.655 357.732L639.951 356.77L709.713 357.732L792.706 365.489L958.031 377.634H752.473L664.788 374.568L540.239 362.663L521.655 357.732Z" fill="url(#svg-robot-flag-6_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M232.683 415.034L303.347 418.942C303.347 418.942 326.982 418.401 363.246 410.825L398.549 424.534L425.852 426.699L431.265 432.952L655.346 435.057L741.046 422.911L783.625 414.974L958.031 377.574L758.186 371.441L723.786 377.574L654.324 373.486L530.015 360.498C530.015 360.498 445.338 370.299 430.182 373.846C415.027 377.394 312.669 391.584 312.669 391.584L214.701 408.841C214.761 408.841 214.039 412.629 232.683 415.034Z" fill="url(#svg-robot-flag-7_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M55.9883 367.653L62.724 372.764L73.6694 370.239L78.7212 367.713L75.7743 362.663C75.7743 362.663 61.8219 363.023 55.9883 367.653Z" fill="#C2FFFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M36.6836 383.707H45.885L52.5004 378.416C52.5004 378.416 45.5242 376.251 36.6836 383.707Z" fill="#C2FFFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.4498 379.979L9.92529 388.037L12.391 391.584H23.9379L31.9365 387.495L20.4498 379.979Z" fill="#C2FFFF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M1009.39 378.115L1176.46 372.764L1177 391.584L1009.39 378.115Z" fill="url(#svg-robot-flag-8_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M807.861 454.718L865.896 460.01H894.222L953.46 457.364L982.868 458.867L1022.92 460.01L1039.7 470.171L1077.11 468.548L1108.92 465.181L1119.69 454.718L1154.51 452.854L1176.46 454.057L1029.36 439.987L951.716 447.323H877.623L807.861 454.718Z" fill="url(#svg-robot-flag-9_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M150.409 402.768L170.797 400.663L185.29 397.777L208.504 400.303L242.423 402.768L282.116 396.274L214.759 408.841L204.956 407.097L200.807 408.841L150.409 402.768Z" fill="url(#svg-robot-flag-10_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M655.344 435.117L693.834 429.645L741.044 422.971L793.065 413.05L726.67 420.686L631.529 433.794L649.15 466.323L655.344 435.117Z" fill="url(#svg-robot-flag-11_linear)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M706.703 305L781.397 312.756L787.591 320.513L756.739 318.048H701.531L660.395 312.756L706.703 305Z" fill="url(#svg-robot-flag-12_linear)" />
            </g>
            <g clipPath="url(#svg-robot-flag-clip-1)">
                <path opacity="0.12" d="M951.18 390.308C976.007 390.308 996.134 385.521 996.134 379.615C996.134 373.71 976.007 368.923 951.18 368.923C926.352 368.923 906.225 373.71 906.225 379.615C906.225 385.521 926.352 390.308 951.18 390.308Z" fill="black" />
                <path d="M948.829 377.972C948.829 377.972 1026.81 383.628 1043.2 280.589C1043.63 277.869 1043.88 275.122 1043.96 272.365C1044.51 248.583 1041.48 130.568 948.633 123H948.402C853.026 129.698 852.527 248.583 853.079 272.365C853.142 275.122 853.4 277.869 853.836 280.589C870.225 383.628 948.313 377.963 948.313 377.963L948.829 377.972Z" fill="url(#svg-robot-flag-13_linear)" />
                <path d="M1060.42 396H1043.99C1043.99 396 1011.41 325.721 948.499 328.047C885.589 325.712 853.008 396 853.008 396H836.584C820.961 344.404 849.937 287.817 853.827 280.589C854.628 285.258 850.168 302.289 948.499 302.289C1046.83 302.289 1042.08 285.59 1043.09 281.245C1046.98 288.472 1076.05 344.395 1060.42 396Z" fill="#1B134E" />
                <path d="M948.703 280.634C948.65 280.733 948.517 280.983 948.312 281.359C948.472 280.983 948.632 280.733 948.703 280.634Z" stroke="#270D0D" strokeMiterlimit="10" />
                <path d="M948.703 281.359C948.499 280.983 948.366 280.733 948.312 280.634C948.383 280.733 948.543 280.983 948.703 281.359Z" stroke="#270D0D" strokeMiterlimit="10" />
                <path d="M1001.48 237.316C1001.48 263.81 982.238 285.258 948.5 285.258C914.761 285.258 895.542 263.81 895.542 237.316C895.542 210.822 914.761 189.32 948.5 189.32C982.238 189.32 1001.48 210.822 1001.48 237.316Z" fill="#262635" />
                <path d="M947.816 232.755C926.21 232.755 907.766 243.331 900.377 258.243C907.766 273.155 927.35 285.787 948.955 285.787C970.56 285.787 989.637 272.868 997.026 257.956C989.646 243.053 969.412 232.755 947.816 232.755Z" fill="#363649" />
                <path d="M933.037 240.585C936.542 240.585 939.384 234.785 939.384 227.63C939.384 220.475 936.542 214.675 933.037 214.675C929.531 214.675 926.689 220.475 926.689 227.63C926.689 234.785 929.531 240.585 933.037 240.585Z" fill="#42E8E0" />
                <path d="M968.146 240.584C971.651 240.584 974.493 234.784 974.493 227.629C974.493 220.474 971.651 214.674 968.146 214.674C964.641 214.674 961.799 220.474 961.799 227.629C961.799 234.784 964.641 240.584 968.146 240.584Z" fill="#42E8E0" />
                <path opacity="0.4" d="M977.431 204.151C978.925 204.151 980.137 202.93 980.137 201.422C980.137 199.915 978.925 198.693 977.431 198.693C975.936 198.693 974.725 199.915 974.725 201.422C974.725 202.93 975.936 204.151 977.431 204.151Z" fill="white" />
                <path opacity="0.4" d="M989.003 217.25C988.193 217.143 987.48 216.595 987.178 215.769C985.727 211.747 981.009 208.255 980.964 208.219C979.958 207.482 979.727 206.055 980.457 205.04C981.187 204.017 982.602 203.793 983.608 204.529C983.848 204.699 989.492 208.865 991.424 214.216C991.851 215.401 991.246 216.703 990.071 217.134C989.715 217.259 989.35 217.295 989.003 217.25Z" fill="#FCFDFF" />
                <path d="M853.008 396L859.622 393.738C859.622 393.738 889.693 333.712 948.508 328.038C948.508 328.038 890.797 321.089 853.008 396Z" fill="#110B35" />
                <path d="M1044.19 396.001L1037.58 393.738C1037.58 393.738 1007.51 333.712 948.694 328.038C948.703 328.038 1006.41 321.089 1044.19 396.001Z" fill="#110B35" />
                <path opacity="0.2" d="M903.206 358.401L924.873 357.737C924.873 357.737 945.918 372.254 993.41 361.364C993.41 361.373 945.918 387.732 903.206 358.401Z" fill="#F2F2F2" />
                <path d="M836.335 310.297L844.365 300.188L849.742 313.78L832.507 350.159L836.335 310.297Z" fill="url(#svg-robot-flag-14_linear)" />
                <path d="M844.365 300.188L846.243 313.098L832.507 350.159L849.742 313.78L844.365 300.188Z" fill="#C1C1C1" />
                <path d="M1060.62 310.297L1051.86 300.359L1047.21 313.781L1064.45 350.159L1060.62 310.297Z" fill="url(#svg-robot-flag-15_linear)" />
                <path d="M1051.86 300.359L1050.7 313.098L1064.45 350.159L1047.21 313.781L1051.86 300.359Z" fill="#C1C1C1" />
                <g opacity="0.39">
                    <path opacity="0.39" d="M981.044 159.972C982.691 164.29 983.475 172.945 983.679 175.584C983.715 176.042 984.08 176.401 984.534 176.428C997.932 177.362 1001.02 171.104 1001.66 169.093C1001.78 168.743 1001.67 168.357 1001.39 168.115C996.062 163.554 989.279 159.702 987.071 158.499C986.715 158.302 986.27 158.365 985.994 158.661C985.478 159.209 984.258 159.954 981.463 159.873H981L981.044 159.972Z" fill="white" />
                    <path opacity="0.39" d="M984.545 151.729C984.055 151.101 982.319 149.26 977.922 147.923C977.806 147.887 977.708 147.797 977.664 147.68L974.067 138.056C973.952 137.76 974.21 137.463 974.521 137.526C977.147 138.083 986.664 140.606 994.916 149C995.138 149.225 995.014 149.62 994.702 149.673C992.966 149.979 988.515 150.805 984.972 151.864C984.812 151.918 984.652 151.864 984.545 151.729Z" fill="white" />
                    <path opacity="0.39" d="M971.002 149.89C970.851 152.188 971.572 153.93 972.106 154.864C972.346 155.286 972.141 155.815 971.687 155.977C968.937 156.937 960.845 159.801 957.506 161.57C957.159 161.75 956.741 161.651 956.518 161.328C954.809 158.877 948.596 149.037 953.652 142.447C953.857 142.178 954.204 142.079 954.515 142.187C956.643 142.941 964.957 146.011 970.583 149.405C970.637 149.441 970.69 149.477 970.735 149.522L970.931 149.719L971.002 149.89Z" fill="white" />
                    <path opacity="0.39" d="M983.278 156.48C984.213 155.124 983.038 152.682 980.67 151.03C978.293 149.378 975.613 149.145 974.679 150.5C973.744 151.856 974.919 154.298 977.287 155.95C979.664 157.611 982.343 157.844 983.278 156.48Z" fill="white" />
                </g>
            </g>
            <g clipPath="url(#svg-robot-flag-clip-2)">
                <path opacity="0.54" d="M744.194 196.473L689.259 0.545836C689.23 0.459651 689.23 0.344738 689.201 0.258553C687.044 1.00549 685.807 3.27503 686.44 5.48712L742.21 204.431C742.44 201.759 743.188 199.059 744.194 196.473Z" fill="#E0A326" />
                <path d="M806.38 376.148L746.915 188.892C746.225 189.589 745.346 190.117 744.354 190.358C742.116 190.939 739.917 190.994 738.021 189.942L798.343 379.874C799.116 382.259 800.992 383.723 802.862 383.549C803.236 383.514 803.631 383.403 804.02 383.218C806.245 382.192 807.316 379.04 806.38 376.148Z" fill="#E0A326" />
                <path d="M746.496 191.59C746.726 191.159 747.013 190.871 747.33 190.67L694.752 3.16012C694.12 0.86185 691.732 -0.459655 689.431 0.172369C687.13 0.804394 685.807 3.18885 686.44 5.48712L742.21 204.431C742.584 199.978 744.396 195.41 746.496 191.59Z" fill="#E0A326" />
                <path opacity="0.54" d="M806.489 376.503C806.406 376.548 806.352 376.59 806.269 376.635C805.88 376.82 805.513 376.929 805.11 376.966C803.211 377.143 801.338 375.714 800.59 373.286L742.565 190.451C740.958 190.638 739.406 190.484 738 189.72L798.358 379.868C799.131 382.256 801.008 383.721 802.878 383.548C803.252 383.513 803.648 383.401 804.037 383.216C806.18 382.234 807.214 379.305 806.489 376.503Z" fill="#E0A326" />
                <path d="M696.852 8.93452C696.852 8.93452 681.436 43.7821 615.685 43.7821C549.935 43.7821 491.577 41.7711 452 112.127L487.55 248.156C487.55 248.156 518.412 182.483 574.095 181.822C629.779 181.161 702.231 193.888 734.416 145.624L696.852 8.93452Z" fill="url(#svg-robot-flag-16_radial)" />
                <path opacity="0.45" d="M655.521 182.167C663.373 181.65 671.023 180.759 678.415 179.323L671.714 31.9747C665.789 35.0774 658.569 37.8927 649.883 39.9899L655.521 182.167Z" fill="white" />
                <path opacity="0.45" d="M490.397 242.726C495.919 232.872 509.38 211.671 529.974 197.048L523.818 51.6249C509.495 55.9341 496.063 62.6279 483.667 72.9126L490.397 242.726Z" fill="white" />
                <path opacity="0.45" d="M468.51 97.5616C468.51 97.5616 490.915 55.0436 548.641 51.6824C548.641 51.6824 523.992 58.4048 516.14 72.9414C508.288 87.4779 540.789 171.997 540.789 171.997L586.722 177.024C586.722 177.024 527.904 170.876 502.679 217.33C502.679 217.33 507.166 193.83 528.45 175.358C528.45 175.358 514.443 89.7475 496.524 88.0525C478.605 86.3575 468.51 97.5616 468.51 97.5616Z" fill="white" />
                <path opacity="0.45" d="M644.218 44.8163C644.218 44.8163 676.575 43.0926 692.768 23.4424L727.455 142.378C727.455 142.378 708.961 168.348 684.111 170.101C684.111 170.101 711.866 162.028 712.442 139.505C713.017 116.982 698.003 52.8889 686.441 46.54C674.878 40.191 680.084 48.867 644.218 44.8163Z" fill="white" />
                <path d="M606.901 113.65C610.657 123.403 612.434 142.916 612.902 148.877C612.982 149.909 613.811 150.714 614.847 150.787C645.407 152.896 652.437 138.771 653.9 134.241C654.154 133.442 653.914 132.577 653.272 132.032C641.123 121.733 625.659 113.051 620.634 110.343C619.819 109.904 618.803 110.037 618.168 110.702C616.992 111.933 614.212 113.623 607.837 113.437H606.788L606.901 113.65Z" fill="white" />
                <path d="M614.894 95.0611C613.784 93.644 609.821 89.4858 599.784 86.472C599.517 86.3922 599.296 86.1926 599.196 85.9265L590.996 64.2176C590.742 63.5523 591.33 62.8737 592.025 63.0201C598.013 64.2842 619.718 69.9592 638.544 88.907C639.051 89.4193 638.771 90.2975 638.056 90.4239C634.1 91.1092 623.955 92.9787 615.863 95.3738C615.502 95.4736 615.127 95.3538 614.894 95.0611Z" fill="white" />
                <path d="M584.006 90.8963C583.652 96.0857 585.31 100.004 586.519 102.127C587.06 103.078 586.606 104.276 585.57 104.635C579.288 106.797 560.838 113.264 553.226 117.256C552.437 117.668 551.475 117.442 550.967 116.717C547.078 111.188 532.891 88.9802 544.438 74.1174C544.906 73.5186 545.701 73.2791 546.416 73.5319C551.261 75.2417 570.227 82.1542 583.057 89.8118C583.184 89.885 583.298 89.9782 583.405 90.078L583.859 90.5304L584.006 90.8963Z" fill="white" />
                <path d="M612.011 105.778C614.137 102.712 611.47 97.2095 606.054 93.4883C600.638 89.7671 594.524 89.2362 592.399 92.3026C590.273 95.3689 592.94 100.871 598.356 104.592C603.772 108.314 609.885 108.845 612.011 105.778Z" fill="white" />
            </g>
            <defs>
                <linearGradient id="svg-robot-flag-0_linear" x1="0" y1="388.012" x2="437.771" y2="388.012" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6BEBED" />
                    <stop offset="1" stopColor="#3994DD" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-1_linear" x1="218" y1="380" x2="1177" y2="454" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3CC2E9" />
                    <stop offset="1" stopColor="#4B3FA3" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-2_linear" x1="588.199" y1="396.274" x2="588.199" y2="605.94" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#053CAA" />
                    <stop offset="1" stopColor="#2791BB" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-3_linear" x1="299.954" y1="564.446" x2="299.954" y2="466.3" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00B4BA" />
                    <stop offset="1" stopColor="#0031A2" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-4_linear" x1="214.73" y1="463.972" x2="385.178" y2="463.972" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4CCCED" />
                    <stop offset="1" stopColor="#39BDDD" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-5_linear" x1="29.0774" y1="462.498" x2="62.6867" y2="462.498" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4CCCED" />
                    <stop offset="1" stopColor="#39BDDD" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-6_linear" x1="521.645" y1="367.197" x2="958.036" y2="367.197" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0059A2" />
                    <stop offset="1" stopColor="#000E77" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-7_linear" x1="214.728" y1="397.799" x2="958.036" y2="397.799" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4CCCED" />
                    <stop offset="1" stopColor="#39BDDD" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-8_linear" x1="1009.36" y1="382.165" x2="1177.02" y2="382.165" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0059A2" />
                    <stop offset="1" stopColor="#000E77" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-9_linear" x1="807.879" y1="455.08" x2="1176.45" y2="455.08" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4CCCED" />
                    <stop offset="1" stopColor="#39BDDD" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-10_linear" x1="216.242" y1="408.823" x2="216.242" y2="396.275" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4CCCED" />
                    <stop offset="1" stopColor="#39BDDD" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-11_linear" x1="712.29" y1="466.3" x2="712.29" y2="413.024" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0059A2" />
                    <stop offset="1" stopColor="#000E77" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-12_linear" x1="660.399" y1="312.748" x2="787.591" y2="312.748" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4CCCED" />
                    <stop offset="1" stopColor="#39BDDD" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-13_linear" x1="867.92" y1="333.796" x2="1029.76" y2="173.321" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBDBF" />
                    <stop offset="0.5832" stopColor="#DDDEDF" />
                    <stop offset="1" stopColor="#F1F1F2" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-14_linear" x1="846.096" y1="330.583" x2="830.006" y2="319.412" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBDBF" />
                    <stop offset="0.5832" stopColor="#DDDEDF" />
                    <stop offset="1" stopColor="#F1F1F2" />
                </linearGradient>
                <linearGradient id="svg-robot-flag-15_linear" x1="1050.78" y1="330.471" x2="1066.87" y2="319.3" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBDBF" />
                    <stop offset="0.5832" stopColor="#DDDEDF" />
                    <stop offset="1" stopColor="#F1F1F2" />
                </linearGradient>
                <radialGradient id="svg-robot-flag-16_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(462 88.5) rotate(9.02454) scale(258.196 257.893)">
                    <stop stopColor="#F20074" />
                    <stop offset="0.0001" />
                    <stop offset="1" stopColor="#3C3CA6" />
                </radialGradient>
                <clipPath id="svg-robot-flag-clip-0">
                    <rect width="1177" height="301" fill="white" transform="translate(0 305)" />
                </clipPath>
                <clipPath id="svg-robot-flag-clip-1">
                    <rect width="233" height="273" fill="white" transform="translate(832 123)" />
                </clipPath>
                <clipPath id="svg-robot-flag-clip-2">
                    <rect width="349" height="387" fill="white" transform="translate(452)" />
                </clipPath>
            </defs>
        </svg>
    )
}
