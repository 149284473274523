export default function Moon(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 72" fill="none">
            <path d="M34.6893 71.0836C53.8013 71.0836 69.2947 55.1898 69.2947 35.5838C69.2947 15.9778 53.8013 0.0839844 34.6893 0.0839844C15.5773 0.0839844 0.083983 15.9778 0.0839814 35.5838C0.0839797 55.1898 15.5773 71.0836 34.6893 71.0836Z" fill="white" />
            <path d="M41.6712 59.8864C44.4482 57.56 44.8135 53.4228 42.4872 50.6457C40.1608 47.8686 36.0238 47.5033 33.2469 49.8297C30.4699 52.1562 30.1046 56.2934 32.4309 59.0705C34.7572 61.8475 38.8943 62.2129 41.6712 59.8864Z" fill="#EBEBEB" />
            <path d="M31.3898 55.476C31.3898 51.8517 34.3288 48.9167 37.9488 48.9167C39.8517 48.9167 41.5564 49.7299 42.7535 51.022C41.5606 49.379 39.6329 48.3058 37.4452 48.3058C33.8211 48.3058 30.8862 51.2449 30.8862 54.8651C30.8862 56.5864 31.5549 58.1509 32.6405 59.3191C31.8604 58.2376 31.3898 56.9125 31.3898 55.476Z" fill="#DFDFDF" />
            <path d="M23.8355 29.8323C24.1528 24.845 20.3673 20.5448 15.3802 20.2274C10.3931 19.9101 6.09303 23.6959 5.77572 28.6832C5.45841 33.6705 9.244 37.9707 14.2311 38.2881C19.2182 38.6054 23.5182 34.8196 23.8355 29.8323Z" fill="#EBEBEB" />
            <path d="M6.45203 30.1056C6.45203 25.1067 10.5013 21.0572 15.5001 21.0572C18.1212 21.0572 20.474 22.18 22.1292 23.9591C20.4864 21.6929 17.824 20.2151 14.8107 20.2151C9.81202 20.2151 5.7627 24.2646 5.7627 29.2635C5.7627 31.6371 6.68732 33.7918 8.18156 35.41C7.10009 33.9157 6.45203 32.087 6.45203 30.1056Z" fill="#DFDFDF" />
            <path d="M54.0658 20.5343C56.1905 20.5343 57.9129 18.8118 57.9129 16.6871C57.9129 14.5623 56.1905 12.8398 54.0658 12.8398C51.9411 12.8398 50.2188 14.5623 50.2188 16.6871C50.2188 18.8118 51.9411 20.5343 54.0658 20.5343Z" fill="#EBEBEB" />
            <path d="M50.5152 17.0407C50.5152 14.9148 52.2365 13.1935 54.3623 13.1935C55.4768 13.1935 56.4798 13.6723 57.1815 14.4277C56.484 13.4659 55.3488 12.8344 54.0692 12.8344C51.9434 12.8344 50.2222 14.5557 50.2222 16.6816C50.2222 17.6929 50.6143 18.6093 51.25 19.2945C50.7877 18.663 50.5152 17.8869 50.5152 17.0407Z" fill="#DFDFDF" />
            <path d="M36.5387 10.4621C37.1483 7.89343 35.5601 5.31692 32.9915 4.70734C30.4229 4.09777 27.8465 5.68596 27.237 8.25468C26.6274 10.8234 28.2156 13.3999 30.7842 14.0095C33.3528 14.6191 35.9292 13.0309 36.5387 10.4621Z" fill="#EBEBEB" />
            <path d="M27.4702 9.80874C27.4702 7.16687 29.6125 5.0286 32.2501 5.0286C33.637 5.0286 34.8795 5.62302 35.7546 6.56419C34.8836 5.36709 33.4802 4.58691 31.8869 4.58691C29.2451 4.58691 27.1069 6.72931 27.1069 9.36705C27.1069 10.6219 27.594 11.7613 28.3865 12.6157C27.8128 11.8232 27.4702 10.8572 27.4702 9.80874Z" fill="#DFDFDF" />
            <path d="M38.9037 8.0213C37.6696 8.0213 36.6665 7.01822 36.6665 5.78397C36.6665 4.54972 37.6696 3.54663 38.9037 3.54663C40.1379 3.54663 41.141 4.54972 41.141 5.78397C41.1368 7.01822 40.1379 8.0213 38.9037 8.0213Z" fill="#EBEBEB" />
            <path d="M36.8401 5.99525C36.8401 4.761 37.8432 3.75791 39.0774 3.75791C39.7254 3.75791 40.3074 4.03448 40.7161 4.47617C40.3116 3.91477 39.6511 3.55151 38.9081 3.55151C37.6739 3.55151 36.6709 4.5546 36.6709 5.78885C36.6709 6.37502 36.8979 6.90752 37.2694 7.30793C36.997 6.93642 36.8401 6.48234 36.8401 5.99525Z" fill="#DFDFDF" />
            <path d="M3.52483 37.8209C2.29064 37.8209 1.2876 36.8178 1.2876 35.5835C1.2876 34.3493 2.29064 33.3462 3.52483 33.3462C4.75902 33.3462 5.76205 34.3493 5.76205 35.5835C5.76205 36.8178 4.75902 37.8209 3.52483 37.8209Z" fill="#EBEBEB" />
            <path d="M1.46026 35.7899C1.46026 34.5557 2.46329 33.5526 3.69749 33.5526C4.34555 33.5526 4.92756 33.8292 5.33621 34.2708C4.93169 33.7094 4.27125 33.3462 3.52825 33.3462C2.29405 33.3462 1.29102 34.3493 1.29102 35.5835C1.29102 36.1697 1.51803 36.7022 1.88953 37.1026C1.6171 36.7352 1.46026 36.2811 1.46026 35.7899Z" fill="#DFDFDF" />
            <path d="M15.9663 19.2989C15.3471 19.2989 14.8477 18.7994 14.8477 18.1802C14.8477 17.561 15.3471 17.0615 15.9663 17.0615C16.5854 17.0615 17.0849 17.561 17.0849 18.1802C17.0849 18.7953 16.5854 19.2989 15.9663 19.2989Z" fill="#EBEBEB" />
            <path d="M14.9343 18.2834C14.9343 17.6642 15.4338 17.1647 16.053 17.1647C16.3749 17.1647 16.668 17.3051 16.8703 17.5239C16.668 17.2432 16.3378 17.0615 15.9663 17.0615C15.3471 17.0615 14.8477 17.561 14.8477 18.1802C14.8477 18.4733 14.9632 18.7416 15.1449 18.9397C15.0128 18.754 14.9343 18.5269 14.9343 18.2834Z" fill="#DFDFDF" />
            <path d="M8.1196 40.054C7.50043 40.054 7.00098 39.5545 7.00098 38.9353C7.00098 38.3161 7.50043 37.8167 8.1196 37.8167C8.73877 37.8167 9.23824 38.3161 9.23824 38.9353C9.23824 39.5545 8.73877 40.054 8.1196 40.054Z" fill="#EBEBEB" />
            <path d="M7.08717 39.0435C7.08717 38.4243 7.58663 37.9249 8.20579 37.9249C8.52775 37.9249 8.82081 38.0652 9.02307 38.284C8.82081 38.0033 8.49059 37.8217 8.1191 37.8217C7.49994 37.8217 7.00049 38.3211 7.00049 38.9403C7.00049 39.2334 7.11607 39.5017 7.29769 39.6999C7.1656 39.5141 7.08717 39.2871 7.08717 39.0435Z" fill="#DFDFDF" />
            <path d="M46.2631 54.0642C45.644 54.0642 45.1445 53.5648 45.1445 52.9456C45.1445 52.3264 45.644 51.8269 46.2631 51.8269C46.8823 51.8269 47.3818 52.3264 47.3818 52.9456C47.3818 53.5648 46.8782 54.0642 46.2631 54.0642Z" fill="#EBEBEB" />
            <path d="M45.2312 53.0488C45.2312 52.4296 45.7307 51.9301 46.3498 51.9301C46.6718 51.9301 46.9649 52.0705 47.1671 52.2892C46.9649 52.0085 46.6346 51.8269 46.2631 51.8269C45.644 51.8269 45.1445 52.3264 45.1445 52.9456C45.1445 53.2387 45.2601 53.507 45.4417 53.7051C45.3096 53.5194 45.2312 53.2923 45.2312 53.0488Z" fill="#DFDFDF" />
            <path d="M53.7016 23.2367C53.0825 23.2367 52.583 22.7372 52.583 22.1181C52.583 21.4989 53.0825 20.9994 53.7016 20.9994C54.3208 20.9994 54.8203 21.4989 54.8203 22.1181C54.8161 22.7372 54.3167 23.2367 53.7016 23.2367Z" fill="#EBEBEB" />
            <path d="M52.6658 22.2266C52.6658 21.6074 53.1652 21.108 53.7844 21.108C54.1064 21.108 54.3994 21.2483 54.6017 21.4671C54.3994 21.1864 54.0692 21.0048 53.6977 21.0048C53.0786 21.0048 52.5791 21.5042 52.5791 22.1234C52.5791 22.4165 52.6947 22.6848 52.8763 22.883C52.7483 22.6972 52.6658 22.4702 52.6658 22.2266Z" fill="#DFDFDF" />
            <path d="M64.5619 32.3596C63.3278 32.3596 62.3247 31.3565 62.3247 30.1222C62.3247 28.888 63.3278 27.8849 64.5619 27.8849C65.7961 27.8849 66.7992 28.888 66.7992 30.1222C66.7992 31.3606 65.8003 32.3596 64.5619 32.3596Z" fill="#EBEBEB" />
            <path d="M62.4983 30.3329C62.4983 29.0986 63.5014 28.0956 64.7356 28.0956C65.3836 28.0956 65.9656 28.3721 66.3743 28.8138C65.9698 28.2524 65.3093 27.8892 64.5663 27.8892C63.3321 27.8892 62.3291 28.8922 62.3291 30.1265C62.3291 30.7127 62.5561 31.2452 62.9276 31.6456C62.6593 31.2741 62.4983 30.8241 62.4983 30.3329Z" fill="#DFDFDF" />
            <path d="M50.2187 64.4748C48.9845 64.4748 47.9814 63.4717 47.9814 62.2375C47.9814 61.0032 48.9845 60.0001 50.2187 60.0001C51.4529 60.0001 52.4559 61.0032 52.4559 62.2375C52.4559 63.4758 51.457 64.4748 50.2187 64.4748Z" fill="#EBEBEB" />
            <path d="M48.1541 62.4479C48.1541 61.2136 49.1571 60.2105 50.3913 60.2105C51.0394 60.2105 51.6214 60.4871 52.03 60.9288C51.6255 60.3674 50.9651 60.0042 50.2221 60.0042C48.9879 60.0042 47.9849 61.0072 47.9849 62.2415C47.9849 62.8277 48.2119 63.3602 48.5834 63.7606C48.3151 63.389 48.1541 62.9391 48.1541 62.4479Z" fill="#DFDFDF" />
            <path d="M46.6059 50.3821C48.6006 50.3821 50.2177 48.765 50.2177 46.7701C50.2177 44.7753 48.6006 43.1582 46.6059 43.1582C44.6112 43.1582 42.9941 44.7753 42.9941 46.7701C42.9941 48.765 44.6112 50.3821 46.6059 50.3821Z" fill="#EBEBEB" />
            <path d="M43.2707 47.1045C43.2707 45.1107 44.8888 43.4926 46.8825 43.4926C47.9309 43.4926 48.8679 43.9384 49.5284 44.6525C48.872 43.7485 47.8112 43.1582 46.6059 43.1582C44.6122 43.1582 42.9941 44.7763 42.9941 46.7701C42.9941 47.7196 43.3615 48.5782 43.96 49.2221C43.5307 48.6277 43.2707 47.8971 43.2707 47.1045Z" fill="#DFDFDF" />
            <path d="M24.3374 60.0043C26.3321 60.0043 27.9492 58.3871 27.9492 56.3923C27.9492 54.3975 26.3321 52.7804 24.3374 52.7804C22.3426 52.7804 20.7256 54.3975 20.7256 56.3923C20.7256 58.3871 22.3426 60.0043 24.3374 60.0043Z" fill="#EBEBEB" />
            <path d="M21.0022 56.7267C21.0022 54.7329 22.6202 53.1148 24.6139 53.1148C25.6624 53.1148 26.5994 53.5606 27.2598 54.2747C26.6035 53.3707 25.5427 52.7804 24.3374 52.7804C22.3437 52.7804 20.7256 54.3985 20.7256 56.3923C20.7256 57.3418 21.093 58.2004 21.6915 58.8443C21.2581 58.2499 21.0022 57.5192 21.0022 56.7267Z" fill="#DFDFDF" />
            <path opacity="0.2" d="M27.0638 57.4601C18.0918 57.4192 9.79091 54.489 3 49.5399C8.19305 62.0945 20.3208 70.9342 34.4898 70.9996C53.4644 71.0895 68.9157 55.4249 68.9996 36.0126C69.0156 32.5348 68.5282 29.1754 67.6255 26C62.732 44.2107 46.4019 57.55 27.0638 57.4601Z" fill="#B8B8B8" />
            <path opacity="0.2" d="M29.957 63.8103C21.4649 63.7696 13.5757 61.1505 7 56.6821C13.2164 65.3215 23.2217 70.9508 34.5325 70.9996C53.2095 71.0852 68.461 55.9407 69 37C62.7956 52.7839 47.632 63.8918 29.957 63.8103Z" fill="#B8B8B8" />
        </svg>
    )
}