export default function RobotOnCurvePath({ pathEnd,...props }) {

    return (
        <svg id="svg-robot-on-curve-path" {...props} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" fill="none">
            <path id="path" stroke="#AFF1FF" xmlns="http://www.w3.org/2000/svg" strokeDasharray="5,5" d="M177 144C114.998 282 213.5 386 325 385C262.998 523 361.499 627 472.999 626C410.997 764 509.499 868 620.999 867C558.997 1005 657.498 1109 768.998 1108" stroke="#AFF1FF" />
            <g xmlns="http://www.w3.org/2000/svg" id="robot" className="transform translate-x-28 translate-y-28">
                <path id="robot-upper-light-blub" d="M32.4896 77.1606C37.0384 84.6747 43.8735 90.535 51.9938 93.8835C60.1142 97.232 69.0933 97.8927 77.6164 95.7689C86.1394 93.6451 93.7586 88.8484 99.3583 82.0811C104.958 75.3138 108.244 66.9315 108.735 58.1616C109.226 49.3916 106.897 40.6948 102.088 33.3446C97.2787 25.9944 90.2427 20.3769 82.0101 17.3148C73.7774 14.2526 64.7807 13.9066 56.3372 16.3275C47.8937 18.7483 40.4471 23.8088 35.0877 30.768L67.6722 55.8619L32.4896 77.1606Z" fill="url(#svg-robot-0_radial)" />
                <path id="robot-body" d="M40.042 54.2656C40.042 54.2656 39.0413 68.091 57.2711 70.9965C57.7524 71.0738 58.2384 71.118 58.726 71.1306C62.9337 71.2285 83.8129 70.6919 85.1519 54.2309L85.1519 54.1899C83.9669 37.2807 62.9337 37.1923 58.726 37.2902C58.2384 37.3012 57.7524 37.347 57.2711 37.4243C39.0413 40.3298 40.0436 54.1741 40.0436 54.1741L40.042 54.2656Z" fill="url(#svg-robot-1_linear)" />
                <path id="robot-wing" d="M36.8524 74.0503L36.8524 71.1369C36.8524 71.1369 49.2862 65.3606 48.8748 54.2072C49.2878 43.0538 36.8524 37.2775 36.8524 37.2775L36.8524 34.3657C45.9808 31.5959 55.9924 36.733 57.271 37.4227C56.445 37.5647 53.4319 36.7741 53.4319 54.2072C53.4319 71.6404 56.3863 70.7976 57.155 70.9775C55.8764 71.6672 45.9824 76.8201 36.8524 74.0503Z" fill="#1B134E" />
                <path id="robot-face" d="M64.927 63.5995C60.2397 63.5995 56.4451 60.1889 56.4451 54.2074C56.4451 48.2259 60.2397 44.8185 64.927 44.8185C69.6143 44.8185 73.4185 48.2259 73.4185 54.2074C73.4185 60.1889 69.6143 63.5995 64.927 63.5995Z" fill="#262635" />
                <path id="robot-under-eye" d="M65.7339 54.0859C65.7339 50.2556 63.8628 46.9855 61.2245 45.6755C58.5862 46.9855 56.3514 50.4576 56.3514 54.2879C56.3514 58.1183 58.637 61.5005 61.2753 62.8104C63.912 61.502 65.7339 57.9147 65.7339 54.0859Z" fill="#363649" />
                <path id="robot-eye" d="M64.3476 51.466C64.3476 52.0875 65.3738 52.5913 66.6396 52.5913C67.9055 52.5913 68.9316 52.0875 68.9316 51.466C68.9316 50.8445 67.9055 50.3407 66.6396 50.3407C65.3738 50.3407 64.3476 50.8445 64.3476 51.466Z" fill="#42E8E0" />
                <path id="robot-eye" d="M64.3486 57.6907C64.3486 58.3122 65.3747 58.816 66.6406 58.816C67.9064 58.816 68.9326 58.3122 68.9326 57.6907C68.9326 57.0692 67.9064 56.5654 66.6406 56.5654C65.3747 56.5654 64.3486 57.0692 64.3486 57.6907Z" fill="#42E8E0" />
                <path id="robot-shadow-dot" opacity="0.4" d="M70.7945 59.3365C70.7945 59.6014 71.0107 59.8162 71.2774 59.8162C71.5441 59.8162 71.7603 59.6014 71.7603 59.3365C71.7603 59.0715 71.5441 58.8567 71.2774 58.8567C71.0107 58.8567 70.7945 59.0715 70.7945 59.3365Z" fill="white" />
                <path id="robot-shadow" opacity="0.4" d="M68.4769 61.3882C68.496 61.2446 68.5929 61.1183 68.739 61.0647C69.4506 60.8074 70.0685 59.971 70.0748 59.9631C70.2051 59.7847 70.4576 59.7437 70.6371 59.8731C70.8182 60.0025 70.8579 60.2535 70.7276 60.4318C70.6975 60.4744 69.9605 61.475 69.0138 61.8175C68.8041 61.8932 68.5738 61.7859 68.4976 61.5776C68.4753 61.5145 68.469 61.4498 68.4769 61.3882Z" fill="#FCFDFF" />
                <path id="robot-wing-detail" d="M36.8527 37.2772L37.2529 38.4498C37.2529 38.4498 47.8728 43.7811 48.8766 54.2085C48.8766 54.2085 50.106 43.9768 36.8527 37.2772Z" fill="#110B35" />
                <path id="robot-wing-detail" d="M36.8521 71.1731L37.2524 70.0005C37.2524 70.0005 47.8723 64.6692 48.8761 54.2419C48.8761 54.2434 50.1055 64.4751 36.8521 71.1731Z" fill="#110B35" />
                <path id="robot-under-shadow" opacity="0.2" d="M43.5045 46.177L43.6221 50.0184C43.6221 50.0184 41.0537 53.7494 42.9804 62.1692C42.9788 62.1692 38.3153 53.7494 43.5045 46.177Z" fill="#F2F2F2" />
                <path id="robot-under-light-blub" d="M13.9134 70.6267C11.8047 67.8576 10.2621 64.7002 9.37366 61.335C8.48521 57.9697 8.2683 54.4623 8.73532 51.0132C9.20234 47.5641 10.3441 44.2408 12.0955 41.2329C13.8469 38.2251 16.1736 35.5916 18.9427 33.483L34.9999 54.5695L13.9134 70.6267Z" fill="url(#svg-robot-2_radial)" />
                <path id="robot-wing-small-gadget" d="M52.0152 34.3215L53.8037 35.7451L51.3989 36.6983L44.9628 33.6429L52.0152 34.3215Z" fill="url(#svg-robot-3_linear)" />
                <path id="robot-wing-small-gadget" d="M53.8037 35.7451L51.5196 36.0781L44.9628 33.6429L51.3989 36.6983L53.8037 35.7451Z" fill="#C1C1C1" />
                <path id="robot-wing-gadget" d="M52.0151 74.0849L53.7734 72.5319L51.3988 71.7081L44.9627 74.7635L52.0151 74.0849Z" fill="url(#svg-robot-4_linear)" />
                <path id="robot-wing-gadget_2" d="M53.7734 72.5319L51.5195 72.3267L44.9627 74.7635L51.3988 71.7081L53.7734 72.5319Z" fill="#C1C1C1" />
            </g>
            <defs>
                <radialGradient id="svg-robot-0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(67.6723 55.8619) rotate(-151.17) scale(30.16)">
                    <stop stopColor="#AFF1FF" />
                    <stop offset="1" stopColor="#AFF1FF" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="svg-robot-1_linear" x1="47.8578" y1="39.9214" x2="76.3087" y2="68.5552" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBDBF" />
                    <stop offset="0.5832" stopColor="#DDDEDF" />
                    <stop offset="1" stopColor="#F1F1F2" />
                </linearGradient>
                <radialGradient id="svg-robot-2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.9999 54.5695) rotate(28.8303) scale(26.5043)">
                    <stop stopColor="#C4C4C4" />
                    <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="svg-robot-3_linear" x1="48.4261" y1="36.052" x2="50.408" y2="33.2033" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBDBF" />
                    <stop offset="0.5832" stopColor="#DDDEDF" />
                    <stop offset="1" stopColor="#F1F1F2" />
                </linearGradient>
                <linearGradient id="svg-robot-4_linear" x1="48.446" y1="72.3402" x2="50.4279" y2="75.1889" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BBBDBF" />
                    <stop offset="0.5832" stopColor="#DDDEDF" />
                    <stop offset="1" stopColor="#F1F1F2" />
                </linearGradient>
            </defs>
        </svg>
    )
}
