export default function Uranus(props) {

    return (
        <svg id="svg-uranus" {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 73" fill="none">
            <g id="shape">
                <path d="M71.4016 36.5667C71.4016 38.2831 71.2791 39.975 71.042 41.626V41.6342C70.8132 43.2852 70.4535 44.9035 70.004 46.4728C69.2602 49.0801 68.214 51.5648 66.9145 53.8861C65.9745 55.5698 64.912 57.1636 63.7269 58.6593C57.2045 66.9062 47.1186 72.1862 35.7904 72.1862C33.7961 72.1862 31.8345 72.0145 29.9301 71.6958C28.4997 71.4669 27.0939 71.14 25.7371 70.7313C22.0101 69.6443 18.5364 67.9524 15.4142 65.7701C14.8666 65.3941 14.3353 64.9936 13.8122 64.585C11.5319 62.8032 9.48035 60.7353 7.69855 58.455C7.51057 58.2261 7.33893 57.9809 7.15911 57.7439C5.93311 56.0929 4.84606 54.3274 3.91429 52.4721C2.45126 49.546 1.37238 46.3829 0.75938 43.0564C0.489659 41.5525 0.301672 40.0159 0.219938 38.4466C0.187245 37.8254 0.170898 37.1961 0.170898 36.5749C0.170898 35.0792 0.268979 33.608 0.440619 32.1613C0.546873 31.3358 0.669472 30.5266 0.832939 29.7175V29.7093C1.66662 25.4346 3.2686 21.4297 5.48358 17.8416C5.99032 17.0242 6.52159 16.2314 7.09373 15.4631C9.26784 12.5126 11.8997 9.89708 14.8666 7.73932C20.7351 3.47283 27.9603 0.947266 35.774 0.947266C39.3294 0.947266 42.7704 1.47036 46.0071 2.44299C49.4889 3.481 52.7501 5.03394 55.6843 7.03641C56.3055 7.45325 56.9266 7.89461 57.5151 8.35232C59.6811 10.0279 61.6508 11.9404 63.3672 14.0573C63.7923 14.5722 64.2009 15.0872 64.5851 15.6266C65.9092 17.4329 67.0534 19.3618 68.0015 21.4052C68.4756 22.3778 68.8924 23.3913 69.2684 24.413C69.7588 25.7697 70.1675 27.151 70.4944 28.5732V28.5814C70.7805 29.8401 71.0012 31.1151 71.1483 32.4228C71.2872 33.608 71.3608 34.8095 71.3771 36.0273C71.4016 36.2153 71.4016 36.3869 71.4016 36.5667Z" fill="url(#uranus-linear-1)" />
                <path d="M63.3834 14.0656C57.6866 16.4685 50.4205 18.4628 42.8601 17.7844C29.652 16.6075 11.8341 20.0485 0.849121 29.7012C1.6828 25.4265 3.28478 21.4216 5.49976 17.8335C6.00651 17.0162 6.53777 16.2233 7.10991 15.455C14.3842 14.2372 23.146 13.0276 30.8371 12.6598C40.7187 12.1857 49.1373 9.76639 55.7005 7.02832C56.3217 7.44516 56.9428 7.88652 57.5313 8.34423C59.6973 10.0279 61.667 11.9405 63.3834 14.0656Z" fill="url(#uranus-linear-2)" />
                <path d="M70.503 28.5815C67.9692 30.0609 64.8143 31.6138 60.8829 33.134C54.6058 35.5697 43.1468 37.752 34.7364 40.1958C31.2055 41.2175 22.8605 38.4712 19.7138 39.4193C13.6083 41.2338 7.94416 46.8326 3.92287 52.464C2.45984 49.538 1.38096 46.3749 0.767957 43.0483C0.498236 41.5444 0.310249 40.0078 0.228516 38.4385C4.76472 32.6028 12.9462 26.3011 26.6203 27.9848C41.2751 29.783 57.4583 25.2794 68.0183 21.4135C68.4923 22.3861 68.9092 23.3996 69.2851 24.4212C69.7674 25.7698 70.176 27.1593 70.503 28.5815Z" fill="url(uranus-linear-3)" />
                <path d="M71.3934 36.0356L71.042 41.6262V41.6344C68.3938 43.1056 65.1653 44.8383 61.4955 46.6692C53.4039 50.715 45.4757 62.8361 34.1311 61.9615C27.5107 61.4466 20.6859 63.539 15.406 65.7703C14.8583 65.3943 14.3271 64.9938 13.804 64.5852C11.5236 62.8034 9.47211 60.7355 7.69032 58.4552C7.50233 58.2263 7.33069 57.9811 7.15088 57.7441C16.2723 52.1208 30.306 44.9855 41.0376 44.5359C50.3797 44.1436 58.455 41.7815 64.585 39.2641C67.2414 38.1689 69.5299 37.041 71.3934 36.0356Z" fill="url(#uranus-linear-4)" />
                <path d="M46.0155 2.44311C36.0358 7.98464 22.8849 8.23801 14.875 7.73126C20.7435 3.46477 27.9687 0.939209 35.7824 0.939209C39.3378 0.947382 42.787 1.47048 46.0155 2.44311Z" fill="url(#uranus-linear-5)" />
                <path d="M63.727 58.6512C57.2046 66.8982 47.1187 72.1781 35.7905 72.1781C33.7962 72.1781 31.8346 72.0065 29.9302 71.6877C32.7173 69.6853 39.305 65.525 47.2168 64.5034C52.8401 63.7923 59.109 61.046 63.727 58.6512Z" fill="url(#uranus-linear-6)" />
            </g>
            <defs>
                <linearGradient id="uranus-linear-1" x1="73.0831" y1="65.9261" x2="15.8319" y2="20.8465" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4F4BA9" />
                    <stop offset="1" stopColor="#4DD0E1" />
                </linearGradient>
                <linearGradient id="uranus-linear-2" x1="0.843073" y1="18.3725" x2="63.3808" y2="18.3725" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.7754" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="uranus-linear-3" x1="0.227208" y1="36.9369" x2="70.5061" y2="36.9369" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.7754" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="uranus-linear-4" x1="7.15938" y1="50.9022" x2="71.3942" y2="50.9022" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.7754" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="uranus-linear-5" x1="14.8768" y1="4.43078" x2="46.0176" y2="4.43078" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.7754" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="uranus-linear-6" x1="29.9267" y1="65.4158" x2="63.7253" y2="65.4158" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.7754" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}
