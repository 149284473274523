export default function Saturn(props) {
    return (
        <svg {...props} id="svg-saturn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 59" fill="none">
            <path d="M20.2975 25.2307C20.019 26.9476 19.8942 28.6645 19.9326 30.3435C19.9902 34.4318 20.941 38.3684 22.6025 41.935C23.294 43.3958 24.0911 44.7902 25.0035 46.1182C29.3829 52.4831 36.3073 57.1121 44.6051 58.4306C50.5307 59.3697 56.3219 58.497 61.4024 56.2015C67.2896 53.5645 72.2068 49.0303 75.2609 43.2725C76.6823 40.569 77.7099 37.6095 78.2285 34.4413C78.3342 33.7678 78.4206 33.0943 78.4878 32.4208C78.5646 31.5766 78.6031 30.7419 78.6031 29.8976C78.6031 27.9151 78.411 25.98 78.0172 24.0829C77.1337 19.729 75.2417 15.6786 72.5526 12.2163C68.1636 6.54384 61.6425 2.4555 53.9305 1.22236C46.4106 0.0271637 39.102 1.77253 33.186 5.58578C32.1584 6.25927 31.1596 6.99915 30.2088 7.79595C29.0371 8.78247 27.9327 9.86384 26.9242 11.0401C23.5533 14.9577 21.1811 19.7954 20.2975 25.2307Z" fill="url(#saturn-linear-1)" />
            <path opacity="0.38" d="M30.2085 7.80547C35.0297 9.35164 41.3203 11.467 47.025 13.6581C53.7093 16.2288 67.7023 20.7914 78.0073 24.0829C77.1237 19.729 75.2318 15.6786 72.5427 12.2163C68.048 10.7935 63.0156 9.76901 58.2328 10.0251C50.4728 10.452 40.3599 7.8529 33.1857 5.58582C32.1485 6.26879 31.1593 7.01816 30.2085 7.80547Z" fill="white" />
            <path opacity="0.38" d="M78.5935 29.9071C76.7303 29.006 60.6725 26.0464 51.1934 22.7928C44.2018 20.3835 33.0516 14.4454 26.9243 11.059C23.5533 14.9671 21.1811 19.8048 20.2975 25.2401C20.019 26.9571 19.8942 28.674 19.9326 30.3529C28.5281 29.964 40.1201 29.3095 48.754 28.323C60.8838 26.9476 73.5226 30.7134 78.4782 32.4303L78.5935 29.9071Z" fill="white" />
            <path opacity="0.38" d="M22.5923 41.935C23.2838 43.3958 24.0809 44.7902 24.9933 46.1182L45.9971 49.3433L61.3826 56.2109C67.2698 53.5739 72.1871 49.0398 75.2411 43.2819C72.7057 42.1342 68.576 41.2141 61.8532 41.3943C45.5169 41.8401 25.9537 42.1057 25.9537 42.1057L22.5923 41.935Z" fill="white" />
            <path d="M23.6581 15.6786C22.9379 16.9497 22.304 18.2966 21.7758 19.691C15.2547 19.6056 11.173 20.2127 10.9521 21.5597C10.7408 22.8687 14.2175 24.6426 20.1239 26.5207C27.154 28.7499 37.6223 31.1118 49.4255 32.99C60.5181 34.7543 70.631 35.7503 77.9396 35.9116C84.9409 36.0633 89.3683 35.4657 89.5988 34.0619C89.8293 32.6295 85.6036 30.6091 78.5831 28.5317C78.5063 27.0424 78.3238 25.5627 78.0357 24.1208C91.558 28.1238 100.221 32.7623 99.6253 36.3859C99.0491 39.924 89.7909 41.6409 76.1917 41.3469C68.1724 41.1856 58.6453 40.3035 48.5036 38.6909C37.7663 36.9835 27.9415 34.6974 20.0279 32.2121C7.81164 28.3799 0.147692 24.0544 0.704721 20.6491C1.26175 17.1583 10.3279 15.4319 23.6581 15.6786Z" fill="#4DD0E1" />
            <defs>
                <linearGradient id="saturn-linear-1" x1="22.2482" y1="49.11" x2="71.1373" y2="13.3505" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#88E9F9" />
                    <stop offset="1" stopColor="#4B3FA3" />
                </linearGradient>
            </defs>
        </svg>
    )
}