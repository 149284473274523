export default function Neptune(props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121 121" >
            <path d="M120.454 60.6284C120.454 60.9624 120.433 61.3173 120.433 61.6305C120.433 61.6513 120.433 61.6513 120.433 61.6513V61.6931C120.412 62.4237 120.371 63.1335 120.329 63.8433C120.287 64.5739 120.225 65.3046 120.162 66.0352C119.619 71.6091 118.325 77.0994 116.237 82.3601C114.63 86.4726 112.542 90.4181 109.995 94.134C107.929 97.161 105.57 100.021 102.877 102.714C100.831 104.76 98.6807 106.639 96.4261 108.33C75.1955 124.279 45.7815 124.279 24.5509 108.35C22.2754 106.659 20.1252 104.781 18.0585 102.735C12.088 96.7644 7.66236 89.8545 4.73975 82.4853C4.73975 82.4853 4.71888 82.4853 4.71888 82.4645C0.543725 71.964 -0.541814 60.5032 1.46226 49.5016C2.94444 41.2766 6.18018 33.2812 11.1486 26.1417C13.1736 23.2399 15.4699 20.4843 18.0376 17.9166C27.056 8.91917 38.2036 3.36622 49.8523 1.27864C60.3528 -0.600175 71.2499 0.339233 81.3329 4.09687C86.7606 6.12182 91.9796 8.94005 96.7393 12.5933C97.115 12.8856 97.4699 13.1569 97.8248 13.4492C99.5784 14.827 101.248 16.3092 102.835 17.9166C109.369 24.4507 114.087 32.133 116.968 40.2954C117.03 40.5251 117.114 40.7338 117.198 40.9635C117.365 41.4854 117.532 42.0072 117.699 42.5083C117.824 42.9258 117.949 43.3224 118.074 43.7399C118.158 43.9904 118.22 44.241 118.304 44.5123C118.325 44.6376 118.367 44.7628 118.408 44.8881C118.471 45.1386 118.534 45.3474 118.596 45.5979C118.721 46.0989 118.847 46.6417 118.972 47.1636C118.993 47.2471 118.993 47.3306 119.035 47.4141C119.327 48.6875 119.536 49.9818 119.744 51.297C119.765 51.4013 119.765 51.5057 119.786 51.6101C119.89 52.3616 119.995 53.0923 120.078 53.8438C120.099 54.0108 120.099 54.1778 120.12 54.3239C120.183 55.0337 120.266 55.7435 120.308 56.4324C120.371 57.2883 120.412 58.1651 120.433 59.021C120.454 59.5429 120.454 60.0857 120.454 60.6284Z" fill="url(#neptune-linear-1)" />
            <path opacity="0.5" d="M18.0786 17.8957C27.0761 8.89822 38.2446 3.34527 49.8933 1.25769C47.5343 15.7455 71.9172 29.3773 51.626 29.7531C26.4289 30.2332 26.9091 43.0718 28.3286 69.2083C29.1219 83.9466 16.1998 84.6355 4.7599 82.4435C0.584742 71.943 -0.500799 60.4822 1.50327 49.4807C3.00633 41.2557 6.2212 33.2602 11.1896 26.1207C13.1937 23.2399 15.49 20.4634 18.0786 17.8957Z" fill="url(#neptune-linear-2)" />
            <path opacity="0.5" d="M95.3608 103.925C95.7783 105.407 96.1332 106.868 96.4254 108.288C94.6719 109.603 92.8557 110.814 90.9977 111.899C74.8608 121.481 55.1749 122.817 38.0568 115.928C33.318 114.029 28.7671 111.482 24.5293 108.33C40.6663 109.979 59.9555 108.935 56.845 93.9462C51.626 68.7491 83.9417 63.9895 95.3608 103.925Z" fill="url(#neptune-linear-3)" />
            <path opacity="0.5" d="M120.454 60.6285V61.0878C120.454 61.2757 120.454 61.4635 120.433 61.6514C120.433 61.6723 120.433 61.6723 120.433 61.6723V61.714C120.412 62.4447 120.37 63.1545 120.328 63.8643C120.286 64.5949 120.224 65.3256 120.161 66.0562C108.784 71.8179 94.4214 64.8872 78.7229 56.5577C69.5167 51.6728 55.6343 57.017 55.8013 51.8189C56.0101 45.0552 64.924 39.4187 73.0029 33.7405C80.9357 28.1667 79.7875 14.9314 81.3532 4.07605C86.7809 6.101 91.9999 8.91923 96.7595 12.5725C96.7178 12.9483 97.0935 13.2196 97.8451 13.4284C99.5986 14.8062 101.269 16.2884 102.855 17.8958C109.389 24.4299 114.107 32.1122 116.988 40.2746C115.965 41.2975 116.049 42.07 117.719 42.4875C117.844 42.905 117.969 43.3016 118.095 43.7191C118.178 43.9696 118.241 44.2202 118.324 44.4915C118.345 44.6168 118.387 44.742 118.429 44.8673C118.491 45.1178 118.533 45.3266 118.596 45.5771C118.742 46.099 118.846 46.6209 118.971 47.1428C118.992 47.2263 118.992 47.3098 119.034 47.3933C119.138 47.8734 119.243 48.3536 119.326 48.8337C119.994 52.1947 120.349 55.5974 120.433 59.0211C120.454 59.543 120.454 60.0857 120.454 60.6285Z" fill="url(#neptune-linear-4)" />
            <defs>
                <linearGradient id="neptune-linear-1" x1="104.491" y1="107.316" x2="26.0997" y2="23.5802" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4B3FA3" />
                    <stop offset="1" stopColor="#3CBEE7" />
                </linearGradient>
                <linearGradient id="neptune-linear-2" x1="46.3613" y1="58.8879" x2="7.5679" y2="24.2091" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.7754" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="neptune-linear-3" x1="24.542" y1="97.369" x2="96.4315" y2="97.369" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.7754" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="neptune-linear-4" x1="55.8115" y1="36.1082" x2="120.446" y2="36.1082" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.7754" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}
