import React from 'react'

export default function StarParticleBlur(props) {
    return (
        <svg {...props} id="svg-star-particle-blur" xmlns="http://www.w3.org/2000/svg" width="520" height="202" viewBox="0 0 520 202" fill="none">
            <g clippth="url(#svg-star-particle-blur-clip0)">
                <path opacity="0.5" d="M12.2413 88.1924C12.406 86.0264 14.2876 84.402 16.4751 84.5432C18.6391 84.708 20.2856 86.5915 20.121 88.7574C19.9563 90.9234 18.0746 92.5479 15.8871 92.4066C13.7231 92.2654 12.1002 90.3819 12.2413 88.1924Z" fill="#AFF1FF" />
                <path opacity="0.5" d="M47.2413 53.6313C47.4059 51.4653 49.2876 49.8408 51.4751 49.9821C53.6391 50.1469 55.2856 52.0303 55.121 54.1963C54.9563 56.3623 53.0746 57.9867 50.8871 57.8455C48.6996 57.7042 47.0766 55.7972 47.2413 53.6313Z" fill="#AFF1FF" />
                <path opacity="0.25" d="M22.5438 48.2398C22.6614 46.6153 24.0727 45.3911 25.7192 45.5088C27.3422 45.6265 28.5654 47.0391 28.4477 48.6871C28.3301 50.3116 26.9188 51.5358 25.2723 51.4181C23.6258 51.2769 22.4027 49.8643 22.5438 48.2398Z" fill="#AFF1FF" />
                <path opacity="0.5" d="M197.85 25.8268C197.85 28.0163 196.086 29.7821 193.898 29.7821C191.711 29.7821 189.946 28.0163 189.946 25.8268C189.946 23.6373 191.711 21.8716 193.898 21.8716C196.086 21.8716 197.85 23.6373 197.85 25.8268Z" fill="#AFF1FF" />
                <path opacity="0.85" d="M230.967 29.7583C230.967 31.9479 229.203 33.7136 227.016 33.7136C224.828 33.7136 223.064 31.9479 223.064 29.7583C223.064 27.5688 224.828 25.8031 227.016 25.8031C229.203 25.8266 230.967 27.5924 230.967 29.7583Z" fill="#AFF1FF" />
                <path opacity="0.5" d="M165.507 62.8836C165.507 65.0731 163.743 66.8153 161.556 66.8153C159.368 66.8153 157.604 65.0496 157.604 62.8836C157.604 60.6941 159.368 58.9283 161.556 58.9283C163.743 58.9519 165.507 60.7176 165.507 62.8836Z" fill="#AFF1FF" />
                <path opacity="0.85" d="M256.206 106.273C256.206 108.463 254.442 110.229 252.254 110.229C250.067 110.229 248.303 108.463 248.303 106.273C248.303 104.084 250.067 102.342 252.254 102.342C254.442 102.318 256.206 104.084 256.206 106.273Z" fill="#AFF1FF" />
                <path opacity="0.25" d="M187.593 69.4049C185.957 69.4049 184.63 68.0768 184.63 66.4385C184.63 64.8002 185.957 63.472 187.593 63.472C189.23 63.472 190.557 64.8002 190.557 66.4385C190.557 68.0768 189.23 69.4049 187.593 69.4049Z" fill="#AFF1FF" />
                <path opacity="0.25" d="M223.675 99.9639C222.039 99.9639 220.712 98.6358 220.712 96.9974C220.712 95.3591 222.039 94.031 223.675 94.031C225.312 94.031 226.639 95.3591 226.639 96.9974C226.639 98.6358 225.312 99.9639 223.675 99.9639Z" fill="#AFF1FF" />
                <path opacity="0.25" d="M233.343 55.797C233.343 57.4215 232.026 58.7634 230.38 58.7634C228.757 58.7634 227.416 57.445 227.416 55.797C227.416 54.149 228.733 52.8306 230.38 52.8306C232.003 52.8306 233.343 54.1725 233.343 55.797Z" fill="#AFF1FF" />
                <path opacity="0.5" d="M520 50.029C520 55.2085 515.79 59.3991 510.615 59.3991C505.44 59.3991 501.23 55.2085 501.23 50.029C501.23 44.8495 505.44 40.6588 510.615 40.6588C515.79 40.6588 520 44.8495 520 50.029Z" fill="#AFF1FF" />
                <path opacity="0.5" d="M492.809 111.006C490.224 111.006 488.129 108.908 488.129 106.321C488.129 103.733 490.224 101.635 492.809 101.635C495.395 101.635 497.49 103.733 497.49 106.321C497.49 108.908 495.395 111.006 492.809 111.006Z" fill="#AFF1FF" />
                <path opacity="0.5" d="M371.814 23.7548C371.814 26.3445 369.721 28.4399 367.134 28.4399C364.546 28.4399 362.453 26.3445 362.453 23.7548C362.453 21.165 364.546 19.0697 367.134 19.0697C369.698 19.0697 371.814 21.165 371.814 23.7548Z" fill="#AFF1FF" />
                <path opacity="0.85" d="M444.966 86.6149C442.381 86.6149 440.285 84.5173 440.285 81.9298C440.285 79.3423 442.381 77.2448 444.966 77.2448C447.551 77.2448 449.647 79.3423 449.647 81.9298C449.647 84.5173 447.551 86.6149 444.966 86.6149Z" fill="#AFF1FF" />
                <path opacity="0.2" d="M373.673 108.18C373.673 110.252 372.003 111.924 369.909 111.924C367.84 111.924 366.146 110.252 366.146 108.18C366.146 106.109 367.816 104.437 369.909 104.437C372.003 104.437 373.673 106.109 373.673 108.18Z" fill="#AFF1FF" />
                <path opacity="0.2" d="M474.979 59.4226C473.42 59.4226 472.157 58.1577 472.157 56.5974C472.157 55.0371 473.42 53.7722 474.979 53.7722C476.538 53.7722 477.802 55.0371 477.802 56.5974C477.802 58.1577 476.538 59.4226 474.979 59.4226Z" fill="#AFF1FF" />
                <path opacity="0.2" d="M459.033 82.8714C457.474 82.8714 456.21 81.6065 456.21 80.0462C456.21 78.4859 457.474 77.2211 459.033 77.2211C460.592 77.2211 461.855 78.4859 461.855 80.0462C461.855 81.6065 460.592 82.8714 459.033 82.8714Z" fill="#AFF1FF" />
                <path opacity="0.2" d="M444.966 50.9707C443.407 50.9707 442.143 49.7058 442.143 48.1455C442.143 46.5852 443.407 45.3203 444.966 45.3203C446.525 45.3203 447.789 46.5852 447.789 48.1455C447.789 49.7058 446.525 50.9707 444.966 50.9707Z" fill="#AFF1FF" />
                <path opacity="0.5" d="M308.965 197.315C308.965 199.905 306.872 202 304.285 202C301.697 202 299.604 199.905 299.604 197.315C299.604 194.725 301.697 192.63 304.285 192.63C306.872 192.606 308.965 194.725 308.965 197.315Z" fill="#AFF1FF" />
                <path opacity="0.2" d="M323.972 89.9108C322.413 89.9108 321.149 88.646 321.149 87.0857C321.149 85.5254 322.413 84.2605 323.972 84.2605C325.531 84.2605 326.794 85.5254 326.794 87.0857C326.794 88.646 325.531 89.9108 323.972 89.9108Z" fill="#AFF1FF" />
                <path opacity="0.2" d="M312.234 136.809C312.234 138.363 310.964 139.634 309.412 139.634C307.859 139.634 306.589 138.363 306.589 136.809C306.589 135.255 307.859 133.984 309.412 133.984C310.988 133.984 312.234 135.255 312.234 136.809Z" fill="#AFF1FF" />
                <path opacity="0.2" d="M327.242 125.555C325.683 125.555 324.419 124.29 324.419 122.73C324.419 121.17 325.683 119.905 327.242 119.905C328.801 119.905 330.064 121.17 330.064 122.73C330.064 124.29 328.801 125.555 327.242 125.555Z" fill="#AFF1FF" />
                <path opacity="0.5" d="M312.705 5.01447C312.705 7.08627 311.035 8.75783 308.942 8.75783C306.872 8.75783 305.178 7.08627 305.178 5.01447C305.178 2.94268 306.848 1.27112 308.942 1.27112C311.035 1.24757 312.705 2.94268 312.705 5.01447Z" fill="#AFF1FF" />
                <path opacity="0.2" d="M343.66 44.4022C343.66 46.474 341.99 48.1455 339.896 48.1455C337.803 48.1455 336.133 46.474 336.133 44.4022C336.133 42.3304 337.803 40.6588 339.896 40.6588C341.99 40.6588 343.66 42.3304 343.66 44.4022Z" fill="#AFF1FF" />
            </g>
            <defs>
                <clipPath id="star-particle-blur-clip0">
                    <rect width="560" height="202" fill="white" transform="matrix(-1 0 0 1 520 0)" />
                </clipPath>
            </defs>
        </svg>
    )
}
